import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Green Light',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, PRODUCT DESIGN',
    description: `Green Light drinks was a fun brand to develop. As a new father I know how important it will be for my son to get the proper vitamins he needs on a daily basis. We designed the two Green Light characters based on the clients children. We wanted them to seem ethnically anonymous so the client could reach a larger market. The brand is meant to be fun, fresh and empowering to children so they can get outside, play and explore. We designed a bright color palette mixed with pastels and gradient combinations to really make the brand pop off the screen.

      The drinks taste amazing as well and even though we aren’t kids ...we had to try them.`,
  },
  caseId: 'green-light',
  contents: [
    'image0.jpg',
    'image1.jpg',
    'image2.jpg',
    'image3.jpg',
    'image4.jpg',
    'image5.jpg',
    'image6.jpg',
    'image7.jpg',
    'image8.jpg',
    'image9.jpg',
    'image10.jpg',
  ],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
